const VerifiedIcon = ({ size = 24, ...props }: { size?: number }) => {
	return (
		<svg
			{...props}
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.82156 4.32639C9.24853 2.97765 10.5102 2 12 2C13.4898 2 14.7515 2.97765 15.1784 4.32639C16.4341 3.67453 18.0175 3.87541 19.0711 4.92901C20.1247 5.98248 20.3257 7.566 19.6739 8.82156C21.0226 9.24853 22 10.5101 22 12C22 13.4899 21.0224 14.7515 19.6736 15.1784C20.3255 16.434 20.1247 18.0175 19.0711 19.0711C18.0175 20.1246 16.4341 20.3255 15.1784 19.6736C14.7515 21.0224 13.4898 22 12 22C10.5102 22 9.24853 21.0224 8.82156 19.6736C7.56586 20.3255 5.98248 20.1246 4.92887 19.0711C3.87527 18.0175 3.67453 16.434 4.32639 15.1784C2.97765 14.7515 2 13.4899 2 12C2 10.5101 2.97765 9.24853 4.32639 8.82156C3.67453 7.566 3.87527 5.98248 4.92887 4.92887C5.98248 3.87541 7.56586 3.67453 8.82156 4.32639ZM16.8902 9.1705C17.0366 9.02405 17.0366 8.78661 16.8902 8.64016L16.3598 8.10984C16.2134 7.96339 15.976 7.96339 15.8295 8.10984L10.625 13.3143L8.4205 11.1098C8.27405 10.9634 8.03661 10.9634 7.89016 11.1098L7.35984 11.6402C7.21339 11.7866 7.21339 12.024 7.35984 12.1705L10.3598 15.1705C10.5063 15.3169 10.7437 15.3169 10.8902 15.1705L16.8902 9.1705Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default VerifiedIcon
